<template>
  <div class="flex gap-3 lg:gap-4 items-center">
    <input
      type="text"
      v-model="filterText"
      class="input-search"
      @keyup.enter="doFilter"
      placeholder="Enter search"
    />

    <div class="flex gap-1.5">
      <button
        class="bg-ansGreen border border-ansGreen text-white py-2 px-5 rounded-l"
        @click="doFilter"
      >
        Go
      </button>

      <button
        class="bg-ansLemon border border-ansGreen text-ansGreen py-2 px-5 rounded-r"
        @click="resetFilter"
      >
        Reset
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filterText: "",
    };
  },
  methods: {
    doFilter() {
      if (this.filterText.trim().length > 3) {
        this.$events.fire("filter-set", this.filterText);
      }
    },
    resetFilter() {
      this.filterText = "";
      this.$events.fire("filter-reset");
    },
  },
};
</script>

<style scoped>
.input-search[type="text"] {
  display: block;
  width: 70%;
  font-family: "Source Serif Pro";
  padding: 0.9rem 0.5rem;
  background: #ffffff;
  border-radius: 0.3125rem;
  border: 0.5px solid #dcddde;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #101011;
}
.input-search:focus {
  outline: none;
  border: 0.5px solid #9cd1b8;
}
.input-search:not(:placeholder-shown) {
  border: 0.5px solid #9cd1b8;
}
.input-search::placeholder {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #a7a7a7;
}
</style>
