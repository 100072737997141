export default [
  {
    path: "/admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/admin/AppFrame.vue"),
    children: [
      {
        path: "dashboard",
        name: "Admin Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/Dashboard.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "agents",
        name: "Admin Agents",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/admin/Agents.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "agents/:id",
        name: "Admin Agent",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/admin/Agent.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "revenues",
        name: "Admin Revenues",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/admin/Revenues.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "items",
        name: "Admin Items",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/admin/Items.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "subscriptions",
        name: "Admin Subscriptions",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/Subscriptions.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "invoices",
        name: "Admin Invoices",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/admin/Invoices.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "payments",
        name: "Admin Payments",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/admin/Payments.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
];
