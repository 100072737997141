export default [
  {
    path: "/agent",
    component: () =>
      import(/* webpackChunkName: "agent" */ "../views/agent/AppFrame.vue"),
    children: [
      {
        path: "dashboard",
        name: "Agent Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "agent" */ "../views/agent/Dashboard.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "invoices",
        name: "Agent Invoices",
        component: () =>
          import(/* webpackChunkName: "agent" */ "../views/agent/Invoices.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "invoices-batch",
        name: "Agent Invoices Batch",
        component: () =>
          import(
            /* webpackChunkName: "agent" */ "../views/agent/InvoicesBatch.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "transactions",
        name: "Agent Transactions",
        component: () =>
          import(
            /* webpackChunkName: "agent" */ "../views/agent/Transactions.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "receipts",
        name: "Agent Receipts",
        component: () =>
          import(/* webpackChunkName: "agent" */ "../views/agent/Receipts.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "payments",
        name: "Agents Payments",
        component: () =>
          import(/* webpackChunkName: "agent" */ "../views/agent/Payments.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "subagents",
        name: "Agent Sub Agents",
        component: () =>
          import(
            /* webpackChunkName: "agent" */ "../views/agent/SubAgents.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "subagents/:id",
        name: "Agent Sub Agent",
        component: () =>
          import(/* webpackChunkName: "agent" */ "../views/agent/SubAgent.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
];
