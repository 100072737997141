<template>
  <div class="flex items-end gap-2">
    <!-- <div class="flex flex-col">
      <label for="date-picker" class="text-sm">Select Date Range</label> -->
    <date-picker
      id="date-picker"
      v-model="dateRange"
      type="date"
      placeholder="Select Date range"
      range
    ></date-picker>
    <!-- </div> -->

    <div class="flex gap-1">
      <button
        class="bg-ansGreen border border-ansGreen text-white py-1 px-3 rounded-l"
        @click="doFilter"
      >
        Go
      </button>

      <button
        class="border border-gray-700 text-gray-500 py-1 px-2 rounded-r"
        @click="resetFilter"
      >
        &#x2715;
      </button>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "DateFilter",

  components: { DatePicker },

  data() {
    return {
      dateRange: [],
    };
  },

  methods: {
    doFilter() {
      // console.log(this.dateRange);
      const [startDate, endDate] = this.dateRange;
      this.$events.fire("filter-date-set", { startDate, endDate });
    },
    resetFilter() {
      this.dateRange = [];
      this.$events.fire("filter-reset", "date");
    },
  },
};
</script>

<style scoped></style>
