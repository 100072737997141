import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import moment from "moment";
import "./assets/css/index.css";
import Spinner from "./components/app/Spinner.vue";
import MyTable from "./components/table/MyTable.vue";
import ApiTable from "./components/table/ApiTable.vue";
import MoneyInput from "./components/form/MoneyInput.vue";
import VueSweetalert2 from "vue-sweetalert2";
import vSelect from "vue-select";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-select/dist/vue-select.css";
import secureAxios from "./services/axiosInstance";

Vue.config.productionTip = false;

Vue.component("spinner", Spinner);
Vue.component("my-table", MyTable);
Vue.component("api-table", ApiTable);
Vue.component("money-input", MoneyInput);
Vue.use(require("vue-moment"));
Vue.component("v-select", vSelect);
Vue.use(VueSweetalert2, {
  confirmButtonColor: "#2FB053",
  cancelButtonColor: "#333231",
});

Vue.prototype.moment = moment;
Vue.prototype.$http = secureAxios;
Vue.prototype.moneyFormatter = (value) => {
  if (isNaN(Number(value))) {
    return value;
  }
  var formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
  });
  return formatter.format(Number(value));
};
Vue.filter("toCurrency", (value) => {
  if (isNaN(Number(value))) {
    return value;
  }
  var formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
  });
  return "₦ " + formatter.format(Number(value));
});

Vue.directive("uppercase", (el) => {
  el.value = el.value.toUpperCase();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
